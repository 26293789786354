import router from '../../router';

export default {
  namespaced: true,
  state: {
    token: localStorage.getItem('jwt_token'),
  },
  getters: {
    isLoggedIn: state => {
      let token = state.token;
      if (token) {
        return true;
      } else {
        return false;
      }
    },
  },
  mutations: {
    SET_USER(state, user) {
      state.token = user.token;
      localStorage.setItem('jwt_token', user.token);
    },
    RESET_USER(state) {
      state.token = null;
      localStorage.removeItem('jwt_token');
    },
  },
  actions: {
    set({ commit }, user) {
      commit('SET_USER', user);
    },
    reset({ commit }) {
      router.push({ name: 'login' });
      commit('RESET_USER');
    },
  },
};
