<template>
  <div class="alert alert-primary mx-auto loginForm">
    <h4 class="alert-heading text-center">PLC</h4>
    <div class="form-group">
      <input type="text" class="form-control" placeholder="Username" v-model="username" v-bind:class="{'is-invalid': validationErrors.username}">
      <div class="invalid-feedback" v-if="validationErrors.username">{{validationErrors.username[0]}}</div>
    </div>
    <div class="form-group">
      <input type="password" class="form-control" placeholder="Password" v-model="password" v-bind:class="{'is-invalid': validationErrors.password}" v-on:keyup.enter="login">
      <div class="invalid-feedback" v-if="validationErrors.password">{{validationErrors.password[0]}}</div>
    </div>
    <div class="form-group form-check">
      <input type="checkbox" class="form-check-input" id="cbRememberMe" v-model="rememberMe">
      <label class="form-check-label" for="cbRememberMe">Remember me</label>
    </div>
    <div class="alert alert-warning" v-if="validationErrors.model">{{validationErrors.model[0]}}</div>
    <div class="text-center">
      <button class="btn btn-primary" @click="login" :disabled="working">Login</button>
    </div>
  </div>
</template>
<script>
import { HTTP } from '../http-common';
import router from '../router';
export default {
  data() {
    return {
      working: false,
      username: '',
      password: '',
      rememberMe: false,
      validationErrors: {},
    };
  },
  created() {
    this.$store.dispatch('auth/reset');
  },
  methods: {
    login() {
      this.working = true;
      localStorage.setItem('jwt_token', null);

      HTTP.post('accounts/signin', {
        username: this.username,
        password: this.password,
        rememberMe: this.rememberMe,
      })
        .catch(error => {
          console.error(error);
          alert('error');
          this.working = false;
        })
        .then(response => response.data)
        .then(data => {
          this.working = false;
          this.validationErrors = data.validationErrors;
          if (data.isValid) {
            this.$store.dispatch('auth/set', data);

            let redirectTo = this.$route.query.redirect || '/';
            router.push(redirectTo);
          }
        });
    },
  },
};
</script>
<style>
.loginForm {
  max-width: 300px;
}
</style>
