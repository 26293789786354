<template>
  <modal ref="modal" modal-type="CreateCancel" modal-title="Create device" @create="save" @close="cancel" @cancel="cancel">
    <div v-if="device">
      <div class="form-group">
        <label for="name">Serienummer</label>
        <input type="text" class="form-control" v-model="device.serialNumber" v-bind:class="{'is-invalid': validationErrors.serialNumber}" v-focus>
        <div class="invalid-feedback" v-if="validationErrors.serialNumber">{{validationErrors.serialNumber[0]}}</div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Rental unit</label>
        <div class="col-sm-7 custom-control custom-switch pt-1">
          <input type="checkbox" class="custom-control-input" id="rentalUnit" v-model="device.rentalUnit">
          <label class="custom-control-label" for="rentalUnit"></label>
        </div>
      </div>
      <div class="form-group">
        <label for="name">Owner</label>
        <base-select required @changed="ownerChanged"></base-select>
      </div>
    </div>
    <div class="form-group">
      <label for="name">Base</label>
      <base-select required @changed="baseChanged"></base-select>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal';
import { HTTP } from '../http-common';
import BaseSelect from '../components/BaseSelect';
export default {
  name: 'device-create-modal',
  components: {
    Modal,
    BaseSelect
  },
  data() {
    return {
      device: null,
      validationErrors: {},
    };
  },
  methods: {
    show() {
      this.validationErrors = {};
      this.device = { serialNumber: '', ownerBase: null };
      this.$refs.modal.show();
    },
    async save() {
      let list = this.device.serialNumber.split(';');
      let successful = 0;
      let lastValidationErrors = null;
      for (var i = 0; i < list.length; i++) {
        let device = {
          serialNumber: list[i].trim(),
          rentalUnit: this.device.rentalUnit,
          base: this.device.base,
          ownerBase: this.device.ownerBase,
        };
        if (lastValidationErrors) break;

        await HTTP.post('devices', device)
          .then((response) => response.data)
          .then((data) => {
            if (data.isValid) {
              successful++;
            } else {
              lastValidationErrors = data.validationErrors;
            }
          });
      }

      if (successful === list.length) {
        this.$emit('successful');
        this.$refs.modal.hide();
      } else if (lastValidationErrors) {
        this.validationErrors = lastValidationErrors;
      }
    },
    cancel() {
      this.$refs.modal.hide();
    },
    ownerChanged(baseId) {
      this.device.ownerBase = { id: baseId };
    },
    baseChanged(baseId) {
      this.device.base = { id: baseId };
    }
  },
};
</script>
