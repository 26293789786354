<template>
  <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content" v-if="visible">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">{{ modalTitle }}</h5>
          <button type="button" class="close" aria-label="Close" v-on:click="close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <slot></slot>
        </div>
        <div class="modal-footer" v-if="modalType === 'custom'">
          <slot name="footer"></slot>
        </div>
        <div class="modal-footer" v-if="modalType === 'SaveCancel'">
          <button type="button" class="btn btn-primary" v-on:click="save()">Save</button>
          <button type="button" class="btn btn-secondary" v-on:click="cancel()">Cancel</button>
        </div>
        <div class="modal-footer" v-if="modalType === 'CreateCancel'">
          <button type="button" class="btn btn-primary" v-on:click="create()">Create</button>
          <button type="button" class="btn btn-secondary" v-on:click="cancel()">Cancel</button>
        </div>
        <div class="modal-footer" v-if="modalType === 'YesNo'">
          <button type="button" class="btn btn-primary" v-on:click="yes()">Yes</button>
          <button type="button" class="btn btn-secondary" v-on:click="no()">No</button>
        </div>
        <div class="modal-footer" v-if="modalType === 'DeleteCancel'">
          <button type="button" class="btn btn-danger" v-on:click="del()">Delete</button>
          <button type="button" class="btn btn-secondary" v-on:click="cancel()">Cancel</button>
        </div>
        <div class="modal-footer" v-if="modalType === 'MoveCancel'">
          <button type="button" class="btn btn-danger" v-on:click="move()">Move</button>
          <button type="button" class="btn btn-secondary" v-on:click="cancel()">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'modal',
  props: ['modalTitle', 'modalType', 'model'],
  // watch: {
  //   modalType(to) {
  //     alert('modalType changed to ' + to)
  //   },
  // },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show: function() {
      this.visible = true;
      $(this.$refs.modal).modal('show');
    },
    hide: function() {
      this.visible = false;
      $(this.$refs.modal).modal('hide');
    },
    save: function() {
      this.$emit('save');
    },
    create: function() {
      this.$emit('create');
    },
    yes: function() {
      this.$emit('yes');
    },
    no: function() {
      this.$emit('no');
    },
    cancel: function() {
      this.$emit('cancel');
    },
    close: function() {
      this.$emit('close');
    },
    del: function() {
      this.$emit('delete');
    },
    move() {
      this.$emit('move');
    },
  },
};
</script>
