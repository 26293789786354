<template>
  <modal ref="modal" modal-type="SaveCancel" modal-title="Update device" @save="save" @close="cancel" @cancel="cancel">
    <div v-if="device && variables">
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Serialnumber</label>
        <div class="col-sm-7">
          <input type="text" readonly class="form-control-plaintext" :value="device.serialNumber">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Unik identifierare</label>
        <div class="col-sm-7">
          <input type="text" readonly class="form-control-plaintext" :value="device.uniqueIdentifier">
        </div>
      </div>
      <div class="form-group row">
        <label class="col-sm-5 col-form-label">Rental unit</label>
        <div class="col-sm-7 custom-control custom-switch pt-1">
          <input type="checkbox" class="custom-control-input" id="rentalUnit" v-model="device.rentalUnit">
          <label class="custom-control-label" for="rentalUnit"></label>
        </div>
      </div>
      <hr />
      <div class="form-group row"  v-for="variable in variables" :key="variable.id">
        <label class="col-sm-5 col-form-label">{{variable.name}}</label>
        <div class="col-sm-7">
          <input type="text" v-model="device.data[variable.id]" :readonly="variable.readonly" v-bind:class="{'form-control-plaintext':variable.readonly, 'form-control':!variable.readonly}">
        </div>
      </div>

      <hr />
      <table class="table" v-if="device">
        <tr>
          <th></th>
          <th colspan="2" class="table-primary" style="text-align: center;">Actual</th>
          <th colspan="4" class="table-warning" style="text-align: center;">Desired</th>
        </tr>
        <tr>
          <th>Key</th>
          <th class="table-primary">Type</th>
          <th class="table-primary">value</th>
          <th class="table-warning">Type</th>
          <th class="table-warning">Set value</th>
          <th class="table-warning">Sync</th>
          <th class="table-warning"></th>
        </tr>
        <tr v-for="(key) in settingKeys" :key="key">
          <td>{{key}}</td>
          <td class="table-primary"><span class="badge badge-info" v-if="device.actual[key]">{{device.actual[key].type}}</span></td>
          <td class="table-primary"> {{device.actual[key] ? device.actual[key].value : 'MISSING'}}</td>
          <td class="table-warning">
            <select class="form-control" v-model="device.desired[key].type" v-if="device.desired[key]">
              <option>string</option>
              <option>bool</option>
              <option>int8</option>
              <option>int16</option>
              <option>int32</option>
              <option>int64</option>
              <option>uint8</option>
              <option>uint16</option>
              <option>uint32</option>
              <option>uint64</option>
            </select>
          </td>
          <td class="table-warning">
            <input type="text" class="form-control" v-model="device.desired[key].value" v-if="device.desired[key]">

          </td>
          <td class="table-warning" style="text-align: center;">

            <div class="custom-control custom-switch" v-if="device.desired[key]">
              <input type="checkbox" class="custom-control-input" :id="key + ':needSync'" v-model="device.desired[key].needSync">
              <label class="custom-control-label" :for="key + ':needSync'"></label>
            </div>
          </td>
          <td class="table-warning">
            <a href="#" @click="removeDesired(key)" v-if="device.desired[key]"><span class="fa fa-minus"></span></a>
            <a href="#" @click="createDesired(key)" v-if="!device.desired[key]"><span class="fa fa-plus"></span></a>
          </td>
        </tr>
      </table>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal';
import { HTTP } from '../http-common';
import Vue from 'vue';

export default {
  name: 'device-update-modal',
  components: {
    Modal,
  },
  data() {
    return {
      device: null,
      variables: null,
      validationErrors: {},
    };
  },
  computed: {
    settingKeys: function() {
      if (this.device) {
        return [...new Set([...Object.keys(this.device.actual), ...Object.keys(this.device.desired)])];
      }
      return [];
    }
  },
  methods: {
    createDesired(key) {
      Vue.set(this.device.desired, key, {
        type: this.device.actual[key].type,
        value: this.device.actual[key].type === 'string' ? '' : undefined
      });
    },
    removeDesired(key) {
      Vue.delete(this.device.desired, key);
    },
    show(device) {
      this.validationErrors = {};
      this.device = Object.assign({}, device);

      HTTP.get('variables')
        .then(response => response.data)
        .then(data => {
          this.variables = data.list;
        });

      this.$refs.modal.show();
    },
    save() {
      HTTP.put(`devices/${this.device.id}`, this.device)
        .then(response => response.data)
        .then(data => {
          if (data.isValid) {
            this.$emit('successful');
            this.$refs.modal.hide();
          } else {
            this.validationErrors = data.validationErrors;
          }
        });
    },
    cancel() {
      this.$refs.modal.hide();
    },
  },
};
</script>
