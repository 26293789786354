<template>
  <div id="app">
    <div class="bg-dark" v-if="isLoggedIn">
      <div class="container">
        <!-- As a heading -->
        <nav class="mainmenu navbar navbar-expand navbar-dark bg-dark">
          <span class="navbar-brand mb-0 h1">PLC</span>
          <ul class="navbar-nav justify-content-center">
            <li class="nav-item">
              <router-link class="nav-link" to="/bases" active-class="active">Bases</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/devices" active-class="active">Devices</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/rental-devices" active-class="active">Rental devices</router-link>
            </li>
          </ul>
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" data-toggle="dropdown">
                <i class="fas fa-user-alt"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="javascript:return false" @click="signout">Sign out</a>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="container mt-3">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
  computed: {
    ...mapState({
      submenu: state => state.submenu.submenu,
    }),
    ...mapGetters('auth', ['isLoggedIn']),
  },
  methods: {
    signout() {
      this.$store.dispatch('auth/reset');
    },
  },
};
</script>

<style lang="scss">
@import 'styles/main';
</style>
