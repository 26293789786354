<template>
  <div>
    <device-update-modal ref="updateModal" @successful="loadDevices" class="modal-fs"></device-update-modal>
    <device-move-modal ref="moveModal" @successful="loadDevices"></device-move-modal>
    <device-assign-ownership-modal ref="assignOwnershipModal" @successful="loadDevices"></device-assign-ownership-modal>
    <device-create-modal ref="deviceCreateModal" @successful="loadDevices"></device-create-modal>
    <div class="card">
      <div class="card-header bg-secondary text-secondary">
        <div class="form-row">
          <div class="col">
            <input type="text" class="form-control" placeholder="Search" v-model="filter.textSearch" v-on:keyup.enter="search">
          </div>
          <div class="col">
            <base-select @changed="baseSelected"></base-select>
          </div>
          <div class="col-auto">
            <button type="submit" class="btn btn-light" @click="search">Search</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="text-right mb-3">
          <button class="btn btn-primary" @click="assignOwnership" :disabled="selectedDevices.length === 0">
            <i class="fas fa-home"></i>Set owner base
          </button>
          &nbsp;
          <button class="btn btn-primary" @click="move" :disabled="selectedDevices.length === 0">
            <i class="fas fa-level-up-alt"></i>Move to base
          </button>
          &nbsp;
          <button class="btn btn-primary" @click="create">
            <i class="fas fa-plus"></i>Add Device(s)
          </button>
        </div>
        <div v-if="isLoading" class="fa-3x text-center">
          <i class="fas fa-sync fa-spin"></i>
        </div>
        <div v-if="devices" class="mb-2 text-right">
          Sort by:
          <div class="btn-group btn-group-toggle btn-group-sm" data-toggle="buttons">
            <label class="btn btn-secondary" v-bind:class="{'active':filter.sortBy ==='serialnumber'}">
              <input type="radio" name="sortBy" autocomplete="off" @click="sortBy('serialnumber')" v-model="filter.sortBy" v-bind:value="'serialnumber'"> SerialNumber
            </label>
            <label class="btn btn-secondary" v-bind:class="{'active':filter.sortBy ==='lastConnected'}">
              <input type="radio" name="sortBy" autocomplete="off" @click="sortBy('lastConnected')" v-model="filter.sortBy" v-bind:value="'lastConnected'"> LastConnected
            </label>
          </div>
        </div>
        <div v-if="devices" class="mb-2 text-right">
          Visar {{pager.from}} till {{pager.to}} av {{pager.total}}
          <div class="btn-group btn-group-sm" role="group">
            <a class="btn btn-secondary" v-bind:class="{'disabled':pager.isFirst}"><i class="fa fa-angle-double-left" @click="prevPage()"></i></a>
            <a class="btn btn-secondary" v-bind:class="{'disabled':pager.isLast}"><i class="fa fa-angle-double-right" @click="nextPage()"></i></a>
          </div>
        </div>
        <div class="list-group" v-if="!isLoading && devices">
          <div class="list-group-item list-group-item-action flex-column align-items-start" :key="device.id" v-for="device in devices.list" v-bind:class="{'list-group-item-secondary':device.selected}" @click.stop="toggle(device)">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{device.serialNumber}} <span class="badge badge-warning" v-if="device.rentalUnit">Rental unit</span></h5>
              <small class="text-right">
                <a href="#" class="btn btn-warning btn-sm mr-1" @click.stop.prevent="returnToOwner(device)" v-if="device.base && device.ownerBase && device.base.id != device.ownerBase.id">Return to owner</a>
                <a href="#" class="btn btn-dark btn-sm" @click.stop.prevent="edit(device.id)">Edit</a>
              </small>
            </div>
            <div class="d-flex w-100 justify-content-between mt-1">
              <small>{{device.uniqueIdentifier}}</small>
              <span class="badge badge-primary">Base: {{device.base ? device.base.name : '-'}}</span>
            </div>
            <div class="d-flex w-100 justify-content-between mt-1">
              <small title="last communication with PLC"><i class="fa fa-clock"></i> {{device.lastConnected | formatFromNow}}</small>
              <span class="badge badge-secondary">Owner: {{device.ownerBase ? device.ownerBase.name : '-'}}</span>
            </div>
            <div class="mt-1">
              <span :key="variable.id" v-for="variable in visibleVariables" style="white-space:nowrap">
                <span class="mr-2" v-if="device.data[variable.id]"><b>{{variable.name}}</b>:  {{device.data[variable.id]}}</span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '../http-common';
import DeviceUpdateModal from '../components/DeviceUpdateModal';
import DeviceMoveModal from '../components/DeviceMoveModal';
import DeviceAssignOwnershipModal from '../components/DeviceAssignOwnershipModal';
import BaseSelect from '../components/BaseSelect';
import DeviceCreateModal from '../components/DeviceCreateModal';

export default {
  name: 'DeviceList',
  props: ['rentalDevices'],
  components: {
    DeviceUpdateModal,
    BaseSelect,
    DeviceMoveModal,
    DeviceAssignOwnershipModal,
    DeviceCreateModal,
  },
  computed: {
    selectedDevices() {
      if (this.devices) {
        return this.devices.list.filter(device => device.selected === true);
      }
      return [];
    },
    pager() {
      return {
        currentPage: this.filter.page + 1,
        pageSize: this.devices.pagination.pageSize,
        isFirst: this.devices.pagination.offset === 0,
        isLast: this.devices.pagination.offset >= this.devices.pagination.totalCount - this.devices.pagination.length,
        from: this.devices.pagination.offset + 1,
        to: Math.min(this.devices.pagination.offset + this.devices.pagination.length, this.devices.pagination.totalCount),
        total: this.devices.pagination.totalCount
      };
    }
  },
  data() {
    return {
      baseId: null,
      isLoading: false,
      devices: null,
      bases: null,
      filter: {
        sortBy: 'serialnumber',
        baseId: null,
        name: null,
        page: 0,
        pageSize: 500
      },
      visibleVariables: [],
    };
  },
  watch: {
    $route: function(to) {
      if (
        this.filter.baseId ||
        this.filter.textSearch
      ) {
        this.loadDevices();
      }
    },
  },
  created() {
    this.filter.baseId = +this.$route.query.baseId || null;
    this.filter.textSearch = this.$route.query.textSearch || null;

    if (
      this.filter.baseId ||
      this.filter.textSearch
    ) {
      this.loadDevices();
    }

    HTTP.get('variables')
      .then(response => response.data)
      .then(data => {
        this.variables = data.list;
        this.visibleVariables = data.list;
      });

    HTTP.get('bases')
      .then(response => response.data)
      .then(data => {
        this.bases = data.list;
      });
  },
  methods: {
    toggle(device) {
      if (device.selected !== true) {
        this.$set(device, 'selected', true);
      } else {
        this.$set(device, 'selected', false);
      }
    },
    nextPage() {
      this.filter.page++;
      this.loadDevices();
    },
    prevPage() {
      this.filter.page--;
      this.loadDevices();
    },
    search() {
      this.loadDevices();
    },
    loadDevices() {
      this.isLoading = true;
      this.devices = null;

      let params = {};
      params.deviceSortBy = this.filter.sortBy;
      params.length = this.filter.pageSize;
      params.offset = this.filter.pageSize * this.filter.page;

      if (this.filter.baseId) {
        params.baseId = this.filter.baseId;
      }
      if (this.filter.textSearch) {
        params.textSearch = this.filter.textSearch;
      }
      if (this.rentalDevices) {
        params.onlyRentals = true;
      }
      HTTP.get('devices', {
        params: params,
      })
        .then(response => response.data)
        .then(data => {
          this.devices = data;

          this.isLoading = false;
        });
    },
    returnToOwner(device) {
      this.$refs.moveModal.show([device], true);
    },
    edit(id) {
      HTTP.get('devices/' + id)
        .then(response => response.data)
        .then(device => {
          this.$refs.updateModal.show(device);
        });
    },
    baseFilter(baseId) {
      this.filter.baseId = baseId;
      this.search();
    },
    move() {
      let selectedDevices = this.selectedDevices;
      this.$refs.moveModal.show(selectedDevices);
    },
    assignOwnership() {
      let selectedDevices = this.selectedDevices;
      this.$refs.assignOwnershipModal.show(selectedDevices);
    },
    baseSelected(baseId) {
      this.filter.baseId = baseId;
      this.loadDevices();
    },
    sortBy(sort) {
      this.filter.sortBy = sort;
      this.loadDevices();
    },
    create() {
      this.$refs.deviceCreateModal.show();
    }
  },
};
</script>
