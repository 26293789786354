<template>
  <modal ref="modal" modal-type="custom" modal-title="Set ownership" @close="hide">
    <div style="overflow:auto; max-height:200px">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Serial number</th>
            <th>Current owner</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="device of devices" :key="device.id">
            <td>{{device.serialNumber}}</td>
            <td>{{device.ownerBase ? device.ownerBase.name : '-'}}</td>
          </tr>
        </tbody>
      </table>
    </div>New Owner:
    <base-select @changed="baseSelected" required v-if="visible"></base-select>
    <template slot="footer">
      <button type="button" class="btn btn-primary" v-on:click="move()" :disabled="!baseId && moving">Set ownership</button>
      <button type="button" class="btn btn-secondary" v-on:click="hide()">Cancel</button>
    </template>
  </modal>
</template>

<script>
import { HTTP } from '../http-common';
import Modal from './Modal';
import BaseSelect from '../components/BaseSelect';

export default {
  name: 'device-assign-ownership-modal',
  props: ['modalTitle'],
  components: {
    modal: Modal,
    BaseSelect,
  },
  data: function() {
    return {
      devices: null,
      baseId: null,
      visible: false,
      moving: false,
    };
  },
  methods: {
    show: function(devices) {
      this.visible = true;
      this.baseId = null;
      this.devices = devices;
      this.$refs.modal.show();
    },
    hide() {
      this.visible = false;
      this.baseId = null;
      this.$refs.modal.hide();
    },
    baseSelected(baseId) {
      this.baseId = baseId;
    },
    move() {
      console.warn(this.devices);
      this.moving = true;
      if (this.baseId) {
        let index = 0;
        let request = () => {
          let device = this.devices[index];
          console.warn('Trying to set owner of ' + device.serialNumber);
          HTTP.post(`devices/${device.id}/setOwnership/${this.baseId}`).then(
            response => {
              index++;
              if (index < this.devices.length) {
                request(); // Start move next device
              } else {
                this.moving = false;
                this.hide();
                this.$emit('successful');
              }
            },
          );
        };

        request(); // Start move devices synchronously
      }
    },
  },
};
</script>
