<template>
  <modal ref="modal" :modal-type="modalType" :modal-title="modalTitle" @save="save" @close="cancel" @cancel="cancel" @delete="del">
    <div v-if="base">
      <div v-if="!deleting">
        <div class="form-group">
          <label for="name">Name</label>
          <input type="text" class="form-control" v-model="base.name" v-bind:class="{'is-invalid': validationErrors.name}" v-focus>
          <div class="invalid-feedback" v-if="validationErrors.name">{{validationErrors.name[0]}}</div>
        </div>
        <div class="form-group form-check">
          <input type="checkbox" class="form-check-input" id="enabled" v-model="base.enabled">
          <label class="form-check-label" for="enabled">Enabled</label>
        </div>
        <div class="form-group">
          <label for="address">Address</label>
          <input type="text" class="form-control" v-model="base.address" v-bind:class="{'is-invalid': validationErrors.address}">
          <div class="invalid-feedback" v-if="validationErrors.address">{{validationErrors.address[0]}}</div>
        </div>
        <div class="form-group">
          <label for="webAddress">Address to customer portal</label>
          <input type="text" class="form-control" v-model="base.webAddress" v-bind:class="{'is-invalid': validationErrors.webAddress}">
          <div class="invalid-feedback" v-if="validationErrors.webAddress">{{validationErrors.webAddress[0]}}</div>
        </div>
        <div class="form-group">
          <label for="port">Port</label>
          <input type="text" class="form-control" v-model="base.port" v-bind:class="{'is-invalid': validationErrors.port}">
          <div class="invalid-feedback" v-if="validationErrors.port">{{validationErrors.port[0]}}</div>
        </div>
        <div class="form-group">
          <label for="tenantId">TenantId</label>
          <input type="text" class="form-control" v-model="base.tenantId" v-bind:class="{'is-invalid': validationErrors.tenantId}">
          <div class="invalid-feedback" v-if="validationErrors.tenantId">{{validationErrors.tenantId[0]}}</div>
        </div>
      </div>
      <div v-if="deleting">
        Are you sure you want to delete
        <span class="font-weight-bold" v-if="base">"{{base.name}}"</span>.
      </div>
    </div>
    <template slot="footer">
      <button type="button" class="btn btn-danger" v-on:click="deleting = true">Delete</button>
      <button type="button" class="btn btn-primary" v-on:click="save()">Save</button>
      <button type="button" class="btn btn-secondary" v-on:click="cancel()">Cancel</button>
    </template>
  </modal>
</template>
<script>
import Modal from './Modal';
import { HTTP } from '../http-common';

export default {
  name: 'base-update-modal',
  components: {
    Modal,
  },
  data() {
    return {
      deleting: false,
      base: null,
      validationErrors: {},
    };
  },
  computed: {
    modalType() {
      return this.deleting ? 'DeleteCancel' : 'custom';
    },
    modalTitle() {
      return this.deleting ? 'Delete base' : 'Update base';
    },
  },
  methods: {
    show(base) {
      this.deleting = false;
      this.validationErrors = {};
      this.base = Object.assign({}, base);
      this.$refs.modal.show();
    },
    save() {
      HTTP.put(`bases/${this.base.id}`, this.base)
        .then(response => response.data)
        .then(data => {
          if (data.isValid) {
            this.$emit('successful');
            this.$refs.modal.hide();
          } else {
            this.validationErrors = data.validationErrors;
          }
        });
    },
    del() {
      HTTP.delete(`bases/${this.base.id}`).then(data => {
        this.$emit('successful');
        this.$refs.modal.hide();
      });
    },
    cancel() {
      if (this.deleting) {
        this.deleting = false;
      } else {
        this.$refs.modal.hide();
      }
    },
  },
};
</script>
