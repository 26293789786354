import Vue from 'vue';
import Router from 'vue-router';
import BaseList from './views/BaseList.vue';
import DeviceList from './views/DeviceList.vue';
import LoginForm from './views/LoginForm.vue';
import auth from './stores/modules/auth';
Vue.use(Router);
let router = new Router({
  routes: [
    {
      name: 'login',
      path: '/login',
      component: LoginForm,
      meta: {
        allowAnonymous: true,
      },
    },
    {
      path: '/bases',
      name: 'bases',
      component: BaseList,
    },
    {
      path: '/devices',
      name: 'devices',
      component: DeviceList
    },
    {
      path: '/rental-devices',
      name: 'rental-devices',
      component: DeviceList,
      props: { rentalDevices: true }
    },
    {
      path: '/',
      redirect: {
        name: 'bases',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some(record => record.meta.allowAnonymous)) {
    if (!auth.getters.isLoggedIn(auth.state)) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
      return;
    }
  }
  next();
});

export default router;
