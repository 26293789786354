<template>
  <modal ref="modal" modal-type="custom" modal-title="Move devices" @close="hide">
    <div style="overflow:auto; max-height:200px">
      <table class="table table-sm">
        <thead>
          <tr>
            <th>Serial number</th>
            <th>From</th>
            <th v-if="toOwner">Owner</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="device of devices" :key="device.id">
            <td>{{device.serialNumber}}</td>
            <td>{{device.base ? device.base.name : '-'}}</td>
            <td v-if="toOwner">{{device.ownerBase ? device.ownerBase.name : ''}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!toOwner">Where do you want to move these devices to?</div>
    <base-select @changed="baseSelected" required v-if="visible && !toOwner"></base-select>
    <template slot="footer">
      <button type="button" class="btn btn-primary" v-on:click="move()" :disabled="!baseId && moving && !toOwner">{{toOwner ?'Move to owner':'Move'}}</button>
      <button type="button" class="btn btn-secondary" v-on:click="hide()">Cancel</button>
    </template>

  </modal>
</template>
<script>
import { HTTP } from '../http-common';
import Modal from './Modal';
import BaseSelect from '../components/BaseSelect';
export default {
  name: 'device-move-modal',
  props: ['modalTitle'],
  components: {
    modal: Modal,
    BaseSelect,
  },
  data: function() {
    return {
      devices: null,
      baseId: null,
      visible: false,
      moving: false,
      toOwner: true,
    };
  },
  methods: {
    show: function(devices, toOwner) {
      this.visible = true;
      this.baseId = null;
      this.devices = devices;
      this.toOwner = toOwner === true;
      this.$refs.modal.show();
    },
    hide() {
      this.visible = false;
      this.baseId = null;
      this.$refs.modal.hide();
    },
    baseSelected(baseId) {
      this.baseId = baseId;
    },
    move() {
      console.warn(this.devices);
      this.moving = true;
      if (this.baseId || this.toOwner) {
        let index = 0;
        let request = () => {
          let device = this.devices[index];
          let destinationBaseId = this.toOwner ? device.ownerBase.id : this.baseId;
          console.warn('Trying to move ' + device.serialNumber);
          HTTP.post(`devices/${device.id}/assign/${destinationBaseId}`).then(
            response => {
              index++;
              if (index < this.devices.length) {
                request(); // Start move next device
              } else {
                this.moving = false;
                this.hide();
                this.$emit('successful');
              }
            },
          );
        };
        request(); // Start move devices synchronously
      }
    },
  },
};
</script>
