<template>
  <div class="dropdown w-100">
    <button type="button" class="btn btn-light dropdown-toggle w-100" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span>{{selectedBase ? selectedBase.name : (required?'':'Any base')}}</span>
    </button>
    <div class="dropdown-menu w-100">
      <div class="px-3 pb-2">
        <input type="text" class="form-control" @change="filter" v-model="nameFilter">
      </div>
      <div class="dropdown-divider" v-if="!required"></div>
      <a class="dropdown-item" @click.stop="changed(null);" v-bind:class="{active: !selectedBase }" v-if="!required">Any base</a>
      <div class="dropdown-divider"></div>
      <div style="max-height:200px;overflow:auto">
        <a class="dropdown-item" href="#" @click.prevent="changed(base)" v-for="base of bases" :key="base.id" v-bind:class="{active: base === selectedBase}">{{base.name}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from '../http-common';

export default {
  name: 'base-select',
  props: {
    value: { type: Number },
    required: { type: Boolean },
  },
  data() {
    return {
      selectedBase: null,
      allBases: null,
      nameFilter: '',
    };
  },
  computed: {
    bases() {
      if (!this.nameFilter) return this.allBases;
      return this.allBases.filter(base =>
        base.name.toLowerCase().includes(this.nameFilter.toLowerCase()),
      );
    },
  },
  created() {
    HTTP.get('bases')
      .then(response => response.data)
      .then(data => {
        this.allBases = data.list;
        this.selectedBase = this.allBases.find(base => base.id === this.value);
      });
  },
  methods: {
    changed(base) {
      this.selectedBase = base;
      if (base) {
        this.$emit('changed', base.id);
      } else {
        this.$emit('changed', null);
      }
    },
    filter() {},
  },
};
</script>
