<template>
  <modal ref="modal" modal-type="CreateCancel" modal-title="Create base" @create="save" @close="cancel" @cancel="cancel">
    <div v-if="base">
      <div class="form-group">
        <label for="name">Name</label>
        <input type="text" class="form-control" v-model="base.name" v-bind:class="{'is-invalid': validationErrors.name}" v-focus>
        <div class="invalid-feedback" v-if="validationErrors.name">{{validationErrors.name[0]}}</div>
      </div>
      <div class="form-group form-check">
        <input type="checkbox" class="form-check-input" id="enabled" v-model="base.enabled">
        <label class="form-check-label" for="enabled">Enabled</label>
      </div>
      <div class="form-group">
        <label for="address">Address</label>
        <input type="text" class="form-control" v-model="base.address" v-bind:class="{'is-invalid': validationErrors.address}">
        <div class="invalid-feedback" v-if="validationErrors.address">{{validationErrors.address[0]}}</div>
      </div>
      <div class="form-group">
        <label for="webAddress">Address to customer portal</label>
        <input type="text" class="form-control" v-model="base.webAddress" v-bind:class="{'is-invalid': validationErrors.webAddress}">
        <div class="invalid-feedback" v-if="validationErrors.webAddress">{{validationErrors.webAddress[0]}}</div>
      </div>
      <div class="form-group">
        <label for="port">Port</label>
        <input type="text" class="form-control" v-model="base.port" v-bind:class="{'is-invalid': validationErrors.port}">
        <div class="invalid-feedback" v-if="validationErrors.port">{{validationErrors.port[0]}}</div>
      </div>
      <div class="form-group">
        <label for="tenantId">TenantId</label>
        <input type="text" class="form-control" v-model="base.tenantId" v-bind:class="{'is-invalid': validationErrors.tenantId}">
        <div class="invalid-feedback" v-if="validationErrors.tenantId">{{validationErrors.tenantId[0]}}</div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from './Modal';
import { HTTP } from '../http-common';

export default {
  name: 'base-create-modal',
  components: {
    Modal,
  },
  data() {
    return {
      base: null,
      validationErrors: {},
    };
  },
  methods: {
    show() {
      this.validationErrors = {};
      this.base = { name: '' };
      this.$refs.modal.show();
    },
    save() {
      HTTP.post('bases', this.base)
        .then(response => response.data)
        .then(data => {
          if (data.isValid) {
            this.$emit('successful');
            this.$refs.modal.hide();
          } else {
            this.validationErrors = data.validationErrors;
          }
        });
    },
    cancel() {
      this.$refs.modal.hide();
    },
  },
};
</script>
