<template>
  <div>
    <base-create-modal ref="createModal" @successful="loadBases"></base-create-modal>
    <base-update-modal ref="updateModal" @successful="loadBases"></base-update-modal>
    <div class="card">
      <div class="card-header bg-secondary text-secondary">
        <div class="form-row">
          <div class="col">
            <div class="form-group has-search">
              <span class="fa fa-search form-control-feedback"></span>
              <input type="text" class="form-control" v-model="filter.name" @keyup.enter="search">
            </div>
          </div>
          <div class="col-auto">
            <button type="submit" class="btn btn-light" @click="search">Search</button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="text-right mb-1">
          <button class="btn btn-primary" @click="create">
            <i class="fas fa-plus"></i>Create
          </button>
        </div>
        <div v-if="isLoading" class="fa-3x text-center">
          <i class="fas fa-sync fa-spin"></i>
        </div>
        <div class="list-group" v-if="!isLoading && bases">
          <div class="list-group-item list-group-item-action flex-column align-items-start" :key="base.id" v-for="base in bases" v-bind:class="{'list-group-item-warning':!base.enabled}">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{base.name}}</h5>
              <small class="text-right">
                <a href="#" class="btn btn-dark btn-sm" @click.stop.prevent="edit(base)">Edit</a>
              </small>
            </div>
            <div class="d-flex w-100 justify-content-between">
              <small>
                {{base.address}}:{{base.port}}
                <span class="badge" v-bind:class="{'badge-success':base.online && base.online.value,'badge-danger':!(base.online && base.online.value)}">{{ base.online && base.online.value ? "Online" : "Offline"}}</span>
                <span class="badge badge-warning ml-1" v-if="!base.enabled">Disabled</span>
                <a class="badge badge-primary ml-1" v-bind:href="base.webAddress" v-if="base.webAddress" target="_blank">Portal</a>
              </small>
              <small class="text-muted">
                {{base.identifier}}
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { HTTP } from '../http-common';
import BaseCreateModal from '../components/BaseCreateModal';
import BaseUpdateModal from '../components/BaseUpdateModal';

export default {
  name: 'BaseList',
  components: {
    BaseCreateModal,
    BaseUpdateModal,
  },
  data() {
    return {
      isLoading: false,
      bases: [],
      filter: {
        name: '',
      },
    };
  },
  methods: {
    edit: function(base) {
      this.$refs.updateModal.show(base);
    },
    create: function() {
      this.$refs.createModal.show();
    },
    search: function() {
      // let query = this.clone(this.filter);
      // this.$router.push({ query: query });
      this.loadBases();
    },
    loadBases() {
      this.isLoading = true;
      HTTP.get('bases', {
        params: {
          name: this.filter.name,
        },
      })
        .then(response => response.data)
        .then(data => {
          this.isLoading = false;
          this.bases = data.list;
        });
    },

    clone(obj) {
      let copy = {};
      Object.keys(obj).forEach(function(key, index) {
        if (obj[key]) {
          copy[key] = obj[key];
        }
      });
      return copy;
    },
  },
  watch: {
    $route: {
      handler: function(to) {
        this.filter = this.clone(to.query);
        this.loadBases();
      },
      immediate: true,
    },
  },
};
</script>
