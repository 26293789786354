import axios from 'axios';
import store from './store';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT_URL,
});

http.interceptors.response.use(null,
  error => {
    if (!error.response) {
      alert('No contact with server');
    }

    if (error.response.status !== 401) {
      alert('error');
    }

    return Promise.reject(error);
  });

http.interceptors.request.use(
  config => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

http.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    if (error.response.status === 401) {
      store.dispatch('auth/reset');
    }

    return Promise.reject(error);
  },
);

export const HTTP = http;
