import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import moment from 'moment';
import 'bootstrap';

Vue.filter('formatFromNow',
  function(value) {
    if (value) {
      return moment.utc(String(value)).local().format(moment.HTML5_FMT.DATE + ' ' + moment.HTML5_FMT.TIME);
    }
    return '-';
  });

window.$ = require('jquery');
window.JQuery = require('jquery');
require('bootstrap');

document.title = 'PLC';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

Vue.directive('focus',
  {
    // When the bound element is inserted into the DOM...
    inserted: function(el) {
      // Focus the element

      let modal = $(el).closest('.modal');
      if (!modal) {
        el.focus();
      } else {
        modal.on('shown.bs.modal',
          function() {
            el.focus();
          });
      }
    },
  });
